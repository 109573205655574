import { Spacing } from 'atomic/legacy/obj.constants'
import styled from 'styled-components'

export const ContactContainer = styled.div`
  display: flex;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #ed0e64;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
`
export const Contact = styled.a`
  display: flex;
  align-items: center;
  min-width: 40%;
  margin-top: 20px;
  font-size: 14px;
  svg {
    margin-right: 7px;
  }

  @media (max-width: 480px) {
    font-size: 13px;
  }
`

export const ModalContent = styled.div`
  width: 100%;

  label {
    color: #7a7a7a;
  }
  * {
    font-family: 'Signika' !important;
  }
`

export const SeparatorLine = styled.div`
  width: 100%;
  border: 0.5px solid #d4d4d4;
  margin: ${Spacing.Medium} 0px;
`

export const AttendanceHours = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

export const AttendanceHoursItem = styled.div`
  width: 100%;
  display: flex;

  svg {
    margin-right: 10px;
  }
  div {
    display: flex;
    flex-direction: column;

    h4 {
      color: #595959;
    }
  }
`
