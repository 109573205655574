import React from 'react'

const SvgIcPrazo = props => (
  <svg viewBox="0 0 20 20" {...props}>
    <defs>
      <path
        d="M12.401 13.625a.838.838 0 01-.01.006L9.08 15.543a.82.82 0 01-.82-1.42l2.952-1.703V9.07a.82.82 0 011.639 0v3.824a.82.82 0 01-.449.731zM12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm0-1.5a8.5 8.5 0 110-17 8.5 8.5 0 010 17z"
        id="ic-prazo_svg__a"
      />
    </defs>
    <g transform="translate(-2 -2)" fillRule="evenodd">
      <mask id="ic-prazo_svg__b" fill="#fff">
        <use xlinkHref="#ic-prazo_svg__a" />
      </mask>
      <use fill={props.fill} fillRule="nonzero" xlinkHref="#ic-prazo_svg__a" />
    </g>
  </svg>
)

export default SvgIcPrazo
