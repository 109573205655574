import React from 'react'

const SvgIcMail = props => (
  <svg
    width="22"
    {...props}
    height="17"
    viewBox="0 0 22 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.66667 0C2.69421 0 1.76157 0.358213 1.07394 0.995837C0.386308 1.63346 0 2.49826 0 3.4V3.7417L11 9.2344L22 3.7434V3.4C22 2.49826 21.6137 1.63346 20.9261 0.995837C20.2384 0.358213 19.3058 0 18.3333 0H3.66667ZM22 5.6729L11.4345 10.948C11.301 11.0147 11.1517 11.0496 11 11.0496C10.8483 11.0496 10.699 11.0147 10.5655 10.948L0 5.6729V13.6C0 14.5017 0.386308 15.3665 1.07394 16.0042C1.76157 16.6418 2.69421 17 3.66667 17H18.3333C19.3058 17 20.2384 16.6418 20.9261 16.0042C21.6137 15.3665 22 14.5017 22 13.6V5.6729Z"
      fill={props.fill}
    />
  </svg>
)

export default SvgIcMail
