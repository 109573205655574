import {
  NeutralBackground,
  WhiteBackground
} from 'site/src/components/atm.background/background.styled'
import { Breadcrumb } from 'site/src/components/atm.breadcrumb/breadcrumb.component'
import {
  BorderRoundWrapper,
  MultipleCollumnWrapper,
  StickButtonWrapper
} from 'site/src/components/atm.wrapper/wrapper.component'
import { SEO } from 'site/src/components/legacy/mol.seo/seo.component'
import { CardKind } from 'site/src/components/mol.card/card.component'
import ImgMovedBackground from 'site/src/components/mol.img-moved-background/img-moved-background.component'
import { SchedulingModal } from 'site/src/components/mol.scheduling-modal/scheduling-modal.component'
import { SchedulingCheckupModal } from 'site/src/components/mol.scheduling-modal/scheduling-checkup-modal.component'
import {
  CardData,
  CardsSection
} from 'site/src/components/org.cards-section/cards-section.component'
import FeatureItemSection from 'site/src/components/org.feature-item-section/feature-item-section.component'
import LinkListSection from 'site/src/components/org.link-list-section/link-list-section.component'
import { PageProps } from 'site/src/utils/local-types'
import { appPaths, getCanonicalUrl, getPathUrl, getUnitDetailPageUrl } from 'utils/path'
import {
  getClientAndDoctorUrl,
  getDetailPageUrl,
  getSearchQueryParam,
  isDoctorUrl
} from 'utils/url'
import LargeSeparatorRow from 'atomic/atm.large-separator-row/large-separator-row.component'
import IndexLayout from 'site/src/components/org.layout/layout.component'
import { ClientDoctorSelectContext } from 'site/src/context/client-doctor-select'
import { CosmicjsInformacoesEstaticas, Query } from 'site/src/data/graphql/graphql-types'
import {
  BodySecondary,
  Button,
  Col,
  Divisor,
  Grid,
  H1,
  H2,
  H4,
  HDisplay,
  LargeSeparator,
  Row,
  Separator
} from 'atomic'
import { LazyLoadImage } from 'atomic/legacy/atm.lazy-load-image'
import { Video } from 'atomic/legacy/atm.video/video.component'
import { BooleanContainer } from 'atomic/legacy/obj.abstract-container'
import { MatchMedia } from 'atomic/legacy/obj.match-media'
import ColorFunc from 'color'
import { graphql } from 'gatsby'
import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { DifferentiatedServiceHeroImageMarginBottom } from './component/differentiated-service-hero-image.styled'
import SvgIcTelefone from 'atomic/atm.svg-icon/ic-telefone'
import {
  AttendanceHours,
  AttendanceHoursItem,
  Contact,
  ContactContainer,
  ModalContent,
  SeparatorLine
} from './component/differentiated-services.styled'
import SvgIcMail from 'atomic/atm.svg-icon/ic-mail'
import SvgIcPrazo from 'atomic/atm.svg-icon/ic-prazo'
import SvgIcWhatsapp from 'atomic/atm.svg-icon/ic-whatsapp'

import { useMediaQuery, useTheme } from '@material-ui/core'
import {
  ContactUsModalBody,
  ContactUsModalDetails,
  ContactUsModalStyled
} from 'site/src/components/mol.contact-us-modal/contact-us-modal.component.style'
import {
  ContactLabel,
  ContactText,
  ContactTextCustom,
  ContactsWrapper,
  LineSeparator,
  SchedulingButton
} from 'site/src/components/mol.scheduling-modal/scheduling-modal.component.style'
import SvgIcCalendarioAgendar from 'atomic/atm.svg-icon/ic-calendario-agendar'
import SvgIcPhoneMobile from 'atomic/atm.svg-icon/ic-phone-mobile'

export interface DifferentiatedServicesDetailTemplateProps {
  slug: string
}

const DifferentiatedServicesDetailTemplate: React.FunctionComponent<PageProps<
  DifferentiatedServicesDetailTemplateProps,
  Query
>> = props => {
  const theme = useTheme()
  const downOfSm = useMediaQuery(theme.breakpoints.down('sm'))
  const isFleury = process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === 'fleury'
  const differentiatedServicesWithoutCTA = [
    '/servicos-diferenciados/check-up', 
    '/servicos-diferenciados/laserterapia', 
    '/servicos-diferenciados/bem-vindo-ao-lar-consultoria-domiciliar-fleury', 
    '/servicos-diferenciados/conultoria-amamentacao'];
  const data = props.data.cosmicjsServicosDiferenciados
  const metadata = data.metadata
  const isDoctor = isDoctorUrl(props.location.pathname)
  const informacoesEstaticas = props.data.cosmicjsInformacoesEstaticas.metadata

  const doctorCards = (metadata.corpoClinico || []).slice(0, 3).map(card => ({
    imgSrc:
      card.metadata.foto.imgix_url ||
      ((props.data as any)
        .ourSpecialistPlaceholderDifferentiatedServices as CosmicjsInformacoesEstaticas).metadata
        .iconePlaceholder.imgix_url,
    imgAlt: metadata.seo.descricaoImagem,
    title: card.title,
    body: card.metadata.descricaoSimples,
    linkText: 'Ver perfil',
    linkTo: getDetailPageUrl(getPathUrl(appPaths.ourSpecialists.path, isDoctor), card.slug),
    kind: CardKind.icon
  })) as CardData[]

  const relatedServicesCards = (metadata.servicosRelacionados || []).slice(0, 3).map(card => ({
    imgSrc: card.metadata.thumbnail.imgix_url,
    imgAlt: metadata.seo.descricaoImagem,
    title: card.title,
    body: card.metadata.descricaoCurta,
    linkText: 'Ver mais',
    linkTo: getDetailPageUrl(getPathUrl(appPaths.differentiatedServices.path, isDoctor), card.slug),
    kind: CardKind.image
  })) as CardData[]

  const unidades = (metadata.unidades || []).map(unit => ({
    name: unit.title,
    slug: `${getUnitDetailPageUrl(
      getPathUrl(appPaths.units.path, isDoctorUrl(props.location.pathname)),
      unit.title
    )}`
  }))

  const linksUnidades = (unidades || []).map(unidade => ({
    name: `- ${unidade.name}`,
    slug: unidade.slug
  }))

  const linkExames = (metadata.grupoExames || []).map(exame => ({
    name: `- ${exame.title}`,
    slug: `${getPathUrl(
      appPaths.search.path,
      isDoctorUrl(props.location.pathname)
    )}${getSearchQueryParam(exame.title)}`
  }))

  const featureDifferentials = (data.metadata.diferenciais || []).map(diferencial => ({
    imgSrc: diferencial.metadata.thumbnail.imgix_url,
    imgAlt: `Icone representando o diferencial "${diferencial.title} "`,
    title: diferencial.title,
    body: diferencial.content
  }))

  const breadcrumbAdditionalDictionary = {}
  breadcrumbAdditionalDictionary[`/${props.pageContext.slug}`] = data.title
  const siteUrl = props.data.site.siteMetadata.siteUrl
  const [clientPath, doctorPath] = getClientAndDoctorUrl(props.location)

  const themeContext = useContext(ThemeContext)
  let modalContent

  if (isFleury) {
    modalContent = (
      <ContactUsModalStyled>
        <H1 style={{ color: '#302A38', fontWeight: 'bold', marginLeft: 0 }}>
          {metadata.informacoes_modal?.titulo}
        </H1>

        <ContactUsModalDetails>
          <p>{metadata.informacoes_modal?.subtitulo}</p>
        </ContactUsModalDetails>

        <ContactUsModalBody style={{ display: 'flex', justifyContent: 'center' }}>
          {informacoesEstaticas.agende_link && !differentiatedServicesWithoutCTA.includes(props.location.pathname) ? <>
            <SchedulingButton
              style={{ backgroundColor: '#D31B50' }}
              onClick={() => window.open(informacoesEstaticas.agende_link, '_blank')}
            >
              <SvgIcCalendarioAgendar />
              <ContactTextCustom style={{ marginLeft: '10px' }}>
                Agendamento Digital
              </ContactTextCustom>
            </SchedulingButton>
          </> : <></>}

          <LineSeparator />

          {metadata.informacoes_modal?.whatsapp && (
            <SchedulingButton
              style={{ backgroundColor: '#FFFFFF' }}
              onClick={() => window.open(metadata.informacoes_modal?.whatsapp, '_blank')}
            >
              <SvgIcWhatsapp fill={'#D31B50'} width={22} />
              <ContactText style={{ marginLeft: '10px' }}>WhatsApp</ContactText>
            </SchedulingButton>
          )}

          {metadata.informacoes_modal?.fone && <ContactsWrapper>
            <SvgIcPhoneMobile width={22} />
            <div style={{ flexDirection: 'column' }}>
              <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
                <ContactText>{metadata.informacoes_modal?.fone}</ContactText>
                {!differentiatedServicesWithoutCTA.includes(props.location.pathname) &&
                <ContactLabel>Grande São Paulo</ContactLabel>}
              </Row>
              {!differentiatedServicesWithoutCTA.includes(props.location.pathname) && <>
                <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
                  <ContactText>0800 704 0822</ContactText>
                  <ContactLabel>Outras localidades</ContactLabel>
                </Row>
              </>}
            </div>
          </ContactsWrapper>}
          <ContactLabel
            style={{
              fontSize: '12px',
              textAlign: 'center',
              marginTop: '16px',
              color: '#343838'
            }}
          >
            {metadata.informacoes_modal?.horarios_atendimento.map((item, index) => (
              <BodySecondary>{item.subtitulo}</BodySecondary>
            ))}
          </ContactLabel>
        </ContactUsModalBody>
      </ContactUsModalStyled>
    )
  } else {
  modalContent = (
    <ModalContent>
      <H2>{metadata.informacoes_modal?.titulo}</H2>
      <Separator />
      <BodySecondary>{metadata.informacoes_modal?.subtitulo}</BodySecondary>
      <Separator />
      <ContactContainer>
        <Contact href={`tel:${metadata.informacoes_modal?.fone}`}>
          <SvgIcTelefone fill={themeContext.color.primary} width={20} />
          <span>{metadata.informacoes_modal?.fone}</span>
        </Contact>
        <Contact href={`mailto:${metadata.informacoes_modal?.email}`}>
          <SvgIcMail fill={themeContext.color.primary} width={22} />
          <span>{metadata.informacoes_modal?.email}</span>
        </Contact>

        <Contact href={`${metadata.informacoes_modal?.whatsapp}`}>
          <SvgIcWhatsapp fill={themeContext.color.primary} width={22} />
          <span>Whatsapp</span>
        </Contact>
      </ContactContainer>
      <Separator />
      <SeparatorLine />
      <Separator />
      <H2>HORÁRIOS</H2>
      <Separator />
      <AttendanceHours>
        {metadata.informacoes_modal?.horarios_atendimento.map((item, index) => (
          <>
            {index !== 0 && <SeparatorLine />}
            <AttendanceHoursItem>
              <SvgIcPrazo width={23} />
              <div>
                <H4>{item.titulo}</H4>
                <BodySecondary>{item.subtitulo}</BodySecondary>
              </div>
            </AttendanceHoursItem>
          </>
        ))}
      </AttendanceHours>
    </ModalContent>
  )
}

return (
  <ClientDoctorSelectContext.Provider value={{ clientUrl: clientPath, doctorUrl: doctorPath }}>
    <IndexLayout location={props.location} showScheduleButton={false}>
      <NeutralBackground>
        <SEO
          socialMedia={{
            canonicalUrl: getCanonicalUrl(
              siteUrl,
              appPaths.differentiatedServices.path,
              props.pageContext.slug
            ),
            title: data.metadata.seo.titulo,
            image: data.metadata.seo.imagem.url,
            imageAlt: data.metadata.seo.descricaoImagem,
            description: data.metadata.seo.descricao
          }}
        />

        <WhiteBackground>
          <Grid>
            <Row>
              <Col xs={12}>
                <Separator />
                <Breadcrumb addtionalDictionary={breadcrumbAdditionalDictionary} />
              </Col>
            </Row>
            <LargeSeparatorRow />
            <Row>
              <Col xs={12} md={5}>
                <H2>{informacoesEstaticas.secao1Assunto}</H2>
                <HDisplay>{data.title}</HDisplay>
                <div dangerouslySetInnerHTML={{ __html: data.content }} />
                <LargeSeparator />

                {/* {props.pageContext.slug === 'check-up' && !downOfSm && ( */}
                {metadata.informacoes_modal?.titulo_do_botao_de_agendamento && !downOfSm && (
                  <BooleanContainer>
                    {schedulingModalBool => (
                      <>
                        <Button
                          id="button-scheduling-differentiated"
                          kind="primary"
                          onClick={schedulingModalBool.setTrue}
                          expanded
                        >
                          {metadata.informacoes_modal?.titulo_do_botao_de_agendamento}
                          {/* Agendar Check-up */}
                        </Button>
                        <SchedulingCheckupModal
                          component={modalContent}
                          open={schedulingModalBool.value}
                          onClose={schedulingModalBool.setFalse}
                        />
                      </>
                    )}
                  </BooleanContainer>
                )}
                <LargeSeparator />
              </Col>
              <Col xs={12} xsOffset={0} smOffset={2} sm={8} mdOffset={2} md={5}>
                <DifferentiatedServiceHeroImageMarginBottom>
                  <ImgMovedBackground
                    aspectRatio={0.82}
                    imgProportionPercentual={'93%'}
                    backgroundProportionPercentual={'93%'}
                    imgixImgSrc={metadata.imagemCapa.imgix_url}
                    imgAlt="Foto do centro integrado"
                    imgPosition="bottom-left"
                    backgroundColor={ColorFunc(themeContext.color.primary)
                      .alpha(0.1)
                      .hsl()
                      .string()}
                  />
                </DifferentiatedServiceHeroImageMarginBottom>
              </Col>
            </Row>
          </Grid>
        </WhiteBackground>
        <NeutralBackground>
          <Grid>
            <LargeSeparatorRow />

            <MatchMedia defaultMinWidth={`64rem`}>{md => md && <LargeSeparatorRow />}</MatchMedia>
            <Row mb>
              <Col xs md={12}>
                <H2>{informacoesEstaticas.secao2Assunto}</H2>
                <MultipleCollumnWrapper numberOfCollumns={2} collumnGap="205px">
                  <div dangerouslySetInnerHTML={{ __html: data.metadata.sobre }} />
                </MultipleCollumnWrapper>
              </Col>
            </Row>
            <LargeSeparator />
            <Divisor />
            <LargeSeparator />
            <Row mb>
              <Col xs={12} md={5}>
                <BorderRoundWrapper>
                  {metadata.videoLink.length > 0 ? (
                    <Video url={metadata.videoLink} />
                  ) : (
                    <LazyLoadImage
                      src={
                        metadata.imagemSubstitutaVideo && metadata.imagemSubstitutaVideo.imgix_url
                      }
                      alt={'Imagem da seção 3'}
                      aspectRatio={9 / 16}
                      fitWidth
                    />
                  )}
                </BorderRoundWrapper>
                <Separator />
              </Col>

              {/* {props.pageContext.slug !== 'check-up' ? ( */}
              {!metadata.informacoes_modal?.titulo_do_botao_de_agendamento ? (
                <Col xs={12} mdOffset={2} md={5}>
                  <H2 cell>{informacoesEstaticas.secao3Assunto}</H2>
                  <HDisplay>{informacoesEstaticas.secao3Titulo}</HDisplay>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.metadata.comoUtilizar
                    }}
                  />
                </Col>
              ) : (
                <Col xs={12} mdOffset={2} md={5}>
                  {modalContent}
                </Col>
              )}
            </Row>
            <LargeSeparatorRow />
          </Grid>
        </NeutralBackground>
        {featureDifferentials.length > 0 && (
          <WhiteBackground>
            <FeatureItemSection items={featureDifferentials} />
          </WhiteBackground>
        )}
        {doctorCards.length > 0 && (
          <CardsSection
            metadata={{
              sectionTitle: informacoesEstaticas.secao5Assunto,
              displayText: informacoesEstaticas.secao5Titulo,
              buttonText: 'Ver todos',
              buttonLinkto: getPathUrl(appPaths.ourSpecialists.path, isDoctor)
            }}
            cardsData={doctorCards}
            id="doctor-cards"
          />
        )}
        {linksUnidades.length > 0 && (
          <LinkListSection category={informacoesEstaticas.secao6Assunto} links={linksUnidades} />
        )}
        {linkExames.length > 0 && (
          <LinkListSection
            title={informacoesEstaticas.secao7Titulo}
            category={informacoesEstaticas.secao7Assunto}
            links={linkExames}
            light
          />
        )}

        {relatedServicesCards.length > 0 && (
          <CardsSection
            metadata={{
              sectionTitle: informacoesEstaticas.secao8Assunto,
              displayText: informacoesEstaticas.secao8Titulo,
              buttonText: 'Ver todos',
              buttonLinkto: getPathUrl(appPaths.differentiatedServices.path, isDoctor)
            }}
            cardsData={relatedServicesCards}
            id="related-services-cards"
          />
        )}

        {!metadata.informacoes_modal?.titulo_do_botao_de_agendamento && (
          <BooleanContainer>
            {schedulingModalBool => (
              <>
                <StickButtonWrapper>
                  <Button
                    id="button-scheduling-differentiated"
                    kind="primary"
                    onClick={schedulingModalBool.setTrue}
                    expanded
                  >
                    Agendar exames e vacinas
                  </Button>
                </StickButtonWrapper>
                <SchedulingModal
                  open={schedulingModalBool.value}
                  onClose={schedulingModalBool.setFalse}
                />
              </>
            )}
          </BooleanContainer>
        )}

        {/* {props.pageContext.slug === 'check-up' && downOfSm && ( */}
        {metadata.informacoes_modal?.titulo_do_botao_de_agendamento && downOfSm && (
          <BooleanContainer>
            {schedulingModalBool => (
              <>
                <StickButtonWrapper>
                  <Button
                    id="button-scheduling-differentiated"
                    kind="primary"
                    onClick={schedulingModalBool.setTrue}
                    expanded
                  >
                    {metadata.informacoes_modal?.titulo_do_botao_de_agendamento}
                  </Button>
                </StickButtonWrapper>
                <SchedulingCheckupModal
                  component={modalContent}
                  open={schedulingModalBool.value}
                  onClose={schedulingModalBool.setFalse}
                />
              </>
            )}
          </BooleanContainer>
        )}
      </NeutralBackground>
    </IndexLayout>
  </ClientDoctorSelectContext.Provider>
)
}

export default DifferentiatedServicesDetailTemplate

export const query = graphql`
  query DifferentiatedServicesDetailBySlug($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    cosmicjsServicosDiferenciados(slug: { eq: $slug }) {
      title
      content
      metadata {
        imagemCapa {
          url
          imgix_url
        }
        thumbnail {
          url
          imgix_url
        }
        videoLink
        imagemSubstitutaVideo {
          imgix_url
        }
        grupoExames {
          slug
          title
        }
        corpoClinico {
          slug
          title
          type_slug
          metadata {
            foto {
              url
              imgix_url
            }
            descricaoSimples
          }
        }
        comoUtilizar
        sobre
        diferenciais {
          title
          content
          metadata {
            thumbnail {
              imgix_url
            }
          }
        }
        servicosRelacionados {
          title
          slug
          metadata {
            descricaoCurta
            thumbnail {
              url
              imgix_url
            }
          }
        }
        unidades {
          slug
          title
        }
        seo {
          titulo
          descricao
          descricaoImagem
          imagem {
            imgix_url
            url
          }
        }
        informacoes_modal {
          titulo
          subtitulo
          fone
          email
          whatsapp
          titulo_do_botao_de_agendamento
          horarios_atendimento {
            titulo
            subtitulo
          }
        }
      }
    }

    cosmicjsInformacoesEstaticas(slug: { eq: "servicos-diferenciados" }) {
      metadata {
        secao1Assunto
        secao2Assunto
        secao3Assunto
        secao3Titulo
        secao5Assunto
        secao5Titulo
        secao6Assunto
        secao7Assunto
        secao7Titulo
        secao8Assunto
        secao8Titulo
        agende_link
      }
    }

    ourSpecialistPlaceholderDifferentiatedServices: cosmicjsInformacoesEstaticas(
      slug: { eq: "corpo-clinico-lista" }
    ) {
      metadata {
        iconePlaceholder {
          imgix_url
        }
      }
    }
  }
`
