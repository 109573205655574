import { getCorporateContactJsonLd } from '@root/src/components/legacy/mol.seo/build-corporate-contact-json-ld'
import {
  Body,
  BodySecondary,
  Button,
  Col,
  Color,
  Divisor,
  H2,
  H3,
  H4,
  Hbox,
  Row,
  Separator
} from 'atomic'
import SvgIcChat from 'atomic/atm.svg-icon/ic-chat'
import SvgIcFormulario from 'atomic/atm.svg-icon/ic-formulario'
import SvgIcMessenger from 'atomic/atm.svg-icon/ic-messenger'
import SvgIcPrazo from 'atomic/atm.svg-icon/ic-prazo'
import SvgIcTelefone from 'atomic/atm.svg-icon/ic-telefone'
import SvgIcWhatsapp from 'atomic/atm.svg-icon/ic-whatsapp'
import SvgIcCalendar from 'atomic/atm.svg-icon/ic-calendar'
import { Modal } from 'atomic/legacy/obj.modal'
import { graphql, StaticQuery } from 'gatsby'
import React, { Component, FunctionComponent, useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { getTelLink, removeTelAreaCode } from 'utils/number'
import { appPaths } from 'utils/path'
import { JsonLd } from '../legacy/mol.seo/json-ld.component'
import {
  SchedulingIconWrapper,
  SchedulingItemWrapper,
  SchedulingModalStyled,
  ObsWhatsapp
} from './scheduling-modal.component.style'
import styled from 'styled-components'

const PhoneLabel = styled.span`
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.1;
  letter-spacing: normal;
  color: #464646;
`

const PhoneSeparator = styled.div`
  border: solid 0.5px;
  border-color: #e8e8e8;
  height: 28px;
  margin: auto 10px;
`

const DivAgendeTexto = styled.div`
  p {
    line-height: 1.4;
    font-size: 12px;
  }
`

const Br = styled.div`
  height: 10px;
`

interface SchedulingProps {
  open: boolean
  onClose: () => void
  component: JSX.Element
}

export const SchedulingCheckupModal: React.FunctionComponent<SchedulingProps> = props => {
  const themeContext = useContext(ThemeContext)
  return (
    <Modal xSmall onClose={props.onClose} opened={props.open}>
      <SchedulingModalStyled>
        <Row mb>
          <Col xs={12}>{props.component}</Col>
        </Row>
      </SchedulingModalStyled>
    </Modal>
  )
}
